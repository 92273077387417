<template>
    <div>
      <base-header class="pb-6" type="primary">
        <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
      </base-header>
  
      <div class="container-fluid mt-5">
          <form action="" @submit.prevent="handleSubmitForm">
            <div class="alert alert-success" v-if="postStatus === 'success'">
              Zmiany zapisane poprawnie
            </div>
            <div class="alert alert-danger" v-if="errorMessage">
              {{ errorMessage }}
            </div>
            <div class="row">
              <div class="col-md-6">
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">Subskrybent- ID {{this.$route.params.id}}</h3>
                    </div>
                  </div>
                  <!-- <p class="mb-0">Dodany {{this.createdAt.substr(0, 10)}} {{this.createdAt.substr(11, 8)}}</p> -->
                  <base-input
                    class="mb-3 "
                    type="text"
                    label="Adres email subskrybenta"
                    placeholder="Email"
                    v-model="email"
                  />
                  <div class="col-6">
                    <h5>Czy aktywny?</h5>
                    <base-switch
                      on-text="Tak"
                      off-text="Nie"
                      v-model="active"
                    ></base-switch>
                  </div>
                  <button class="btn btn-primary">
                    Submit form
                  </button>
                </card>
              </div>
            </div>
          </form>
          <form action="" @submit.prevent="handleDelete">
            <button class="btn btn-primary">
              Usuń subskrybenta
            </button>
          </form>
        </div>
    </div>
  </template>
  
  <script>
  import Datepicker from "@/components/Datepicker/index.vue";
  
  import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
  import { mapState, mapMutations, mapActions } from "vuex";
  
  export default {
    name: 'NewsletterView',
    components: {
      BreadcrumbHeader,
      Datepicker
    },
    computed: {
      ...mapState('NewsletterViewModule', ['newsletterInformation', 'errorMessage', 'postStatus']),
      email: {
        get() {
          return this.newsletterInformation.email
        },
        set(newVal){
          this.updateNewsletterField({
            field: 'email',
            value: newVal
          })
        }
      },
      createdAt: {
        get() {
          return this.newsletterInformation.createdAt
        },
        set(newVal){
          this.updateNewsletterField({
            field: 'createdAt',
            value: newVal
          })
        }
      },
      updatedAt: {
        get() {
          return this.newsletterInformation.updatedAt
        },
        set(newVal){
          this.updateNewsletterField({
            field: 'updatedAt',
            value: newVal
          })
        }
      },
      active: {
        get() {
          return this.newsletterInformation.active
        },
        set(newVal){
          this.updateNewsletterField({
            field: 'active',
            value: newVal
          })
        }
      },
      breadcrumb() {
        return [
          {title: 'Marketing', link: {name: 'coupons'}},
          {title: `Newsletter ID: ${this.$route.params.id}`}
        ];
      },
    },
    methods: {
      ...mapMutations('NewsletterViewModule', ['updateNewsletterField']),
      ...mapActions('NewsletterViewModule', ['submitForm', 'deleteForm', 'fetchNewsletterInformation']),
      handleSubmitForm(){
        const method = this.$route.params.id ? 'delete' : 'post';
  
        try {
          this.submitForm({
            id: this.$route.params.id ?? '',
            method
          })
          this.formStatus = 'success'
        } catch (e) {
          console.log(e)
        }
      },
      handleDelete(){
      const method = this.$route.params.id ? 'delete' : 'post';
      console.log('eeeeeeeeeee')
  
      try {
        this.deleteForm({
          id: this.$route.params.id ?? '',
          method
        })
        this.formStatus = 'success'
      } catch (e) {
        console.log(e)
      }
    }
    },
    async mounted() {
      await this.fetchNewsletterInformation(this.$route.params.id)
    }
  };
  </script>
  